<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="app-body">
          <!-- BOC:[error] -->
          <ApiErrorDialog v-if="api.isError" :api="api" />
          <!-- EOC -->
          <div class="pe-2 ps-3 app-title text-h5" v-if="book">
            {{ book.name }}
            <v-spacer></v-spacer>
            <CloseBtn size="40" :callback="exit" />
          </div>

          <div class="px-5">
            <div v-if="quests.length > 0">
              <div class="text-h5">{{ $t("string.quest") }}</div>
              <v-carousel
                :show-arrows="false"
                height="auto"
                hide-delimiter-background
                class="pb-8"
                draggable
                :value="99"
              >
                <v-carousel-item
                  v-for="(item, i) in quests"
                  :key="i + 'q'"
                  :value="
                    quests[i - 1] && quests[i - 1].PlayerQuest.timestampClaimed
                      ? 99
                      : i
                  "
                >
                  <v-card class="my-2 list-card" flat>
                    <div class="d-flex flex-no-wrap align-center">
                      <div
                        class="text-center mx-4 mt-2"
                        v-for="(rwd, j) in item.Reward"
                        :key="j + 'qi'"
                      >
                        <v-img
                          contain
                          max-height="50"
                          max-width="50"
                          :src="rwd.Item.images.icon.url"
                        >
                        </v-img>
                        <div class="me-1">+{{ rwd.quantity }}</div>
                      </div>

                      <div style="width: 100%" class="pa-2">
                        <div class="text-h6">
                          {{ item.name }}
                        </div>
                        <div class="d-flex flex-no-wrap align-center">
                          <div style="width: 100%" class="pe-3">
                            <v-progress-linear
                              :value="item.progress"
                              color="green"
                              height="20"
                              rounded
                            >
                              {{ item.numerator }} /
                              {{ item.denominator }}</v-progress-linear
                            >
                          </div>
                          <v-icon
                            large
                            v-if="item.PlayerQuest.timestampClaimed"
                            color="green"
                          >
                            mdi-check-circle
                          </v-icon>
                          <v-btn
                            v-else-if="item.progress == 100"
                            color="yellow"
                            class="text-h6 constant-tilt-shake"
                            @click="claimQuest(item)"
                            :loading="api.isLoading && selectedItem == item"
                          >
                            {{ $t("action.claim") }}
                          </v-btn>
                          <v-btn v-else color="grey" dark depressed>
                            <v-icon> mdi-lock </v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-carousel-item>
              </v-carousel>
            </div>
            <v-divider class="my-3" v-if="quests.length > 0"></v-divider>
            <div v-if="chapters.length > 0">
              <div class="text-h5 my-4">{{ $t("string.chapters") }}</div>
              <v-card
                v-for="(item, i) in chapters"
                :key="i + 'all'"
                class="my-2 list-card"
                flat
              >
                <div class="d-flex flex-no-wrap">
                  <div
                    style="width: 100%"
                    class="d-flex flex-column justify-space-between pa-2"
                  >
                    <div class="d-flex align-center">
                      <v-icon
                        color="green"
                        v-if="
                          item.PlayerChapter.length > 0 &&
                          item.PlayerChapter[0].isRead
                        "
                      >
                        mdi-check-circle
                      </v-icon>
                      <v-icon v-else>mdi-circle-outline</v-icon>
                      <div class="mx-1">
                        {{
                          JSON.parse(item.translatableSection)[$_getLocale()]
                            ? JSON.parse(item.translatableSection)[
                                $_getLocale()
                              ]
                            : JSON.parse(item.translatableSection)[subjectLang]
                        }}
                      </div>

                      <v-spacer></v-spacer>

                      <div v-if="item.PlayerChapter.length > 0">
                        <v-icon
                          color="amber"
                          v-for="(star, i) in item.totalCollectableStar"
                          :key="i"
                          >{{
                            item.PlayerChapter[0].topStar > i
                              ? "mdi-star"
                              : "mdi-star-outline"
                          }}</v-icon
                        >
                      </div>
                      <div
                        v-else-if="item.minStarToUnlock"
                        class="d-flex align-center"
                      >
                        <v-icon>mdi-star</v-icon>
                        <span class="ml-1">{{ item.minStarToUnlock }}</span>
                      </div>
                    </div>
                    <div class="d-flex align-center mt-3">
                      <div class="text-h5 mx-2">
                        {{
                          item.PlayerChapter.length > 0
                            ? JSON.parse(item.translatableName)[$_getLocale()]
                              ? JSON.parse(item.translatableName)[$_getLocale()]
                              : JSON.parse(item.translatableName)[subjectLang]
                            : "???"
                        }}
                      </div>
                      <v-divider></v-divider>
                      <v-btn
                        v-if="item.PlayerChapter.length > 0"
                        color="blue"
                        dark
                        class="text-h6"
                        @click="read(item)"
                      >
                        {{ $t("string.read") }}
                      </v-btn>
                      <v-btn
                        v-else-if="
                          (book.PlayerBook[0].totalStar >=
                            item.minStarToUnlock &&
                            i == 0) ||
                          (book.PlayerBook[0].totalStar >=
                            item.minStarToUnlock &&
                            i > 0 &&
                            chapters[i - 1].PlayerChapter.length > 0 &&
                            chapters[i - 1].PlayerChapter[0].isRead)
                        "
                        color="green"
                        dark
                        class="constant-tilt-shake"
                        :loading="api.isLoading && selectedItem == item"
                        @click="unlock(item)"
                      >
                        <v-icon> mdi-lock </v-icon>
                      </v-btn>
                      <v-btn v-else depressed color="grey" dark>
                        <v-icon> mdi-lock </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </v-card>
            </div>

            <div class="d-flex justify-right mt-6">
              <v-divider class="my-3"></v-divider>
              <v-btn
                small
                dark
                color="black"
                v-if="settings.debug"
                @click="resetBook"
              >
                <v-icon color="red" small> mdi-record </v-icon>
                Reset
              </v-btn>
            </div>
          </div>

          <UnlockDialog
            v-if="chapterUnlocked"
            :callbackClose="() => (chapterUnlocked = false)"
            :showDialog="true"
            :title="$t('string.chapterUnlocked')"
            :message="selectedItem.name"
          />
          <RewardDialog
            v-if="rwdDialog"
            :callbackClose="() => (rwdDialog = false)"
            :showDialog="true"
            :rewards="rewards"
          />
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
      
  <script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
    RewardDialog: () =>
      import(
        /* webpackChunkName: "component-reward" */ "@/components/ABC/RewardDialog"
      ),
    UnlockDialog: () =>
      import(
        /* webpackChunkName: "component-unlock" */ "@/components/ABC/UnlockDialog"
      ),
  },
  computed: mapState({
    settings: (state) => state.settings.data,
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    rwdDialog: false,
    book: {},
    chapters: [],
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    quests: [],
    selectedItem: {
      name: "",
      section: "",
    },
    chapterUnlocked: false,
    subjectLang: "en",
    rewards: [],
  }),
  async created() {
    await this.browse();
    await this.getQuest();

    if (this.book.subject == "Mandarin") {
      this.subjectLang = "zh";
    } else if (this.book.subject == "Malay") {
      this.subjectLang = "ms";
    } else {
      this.subjectLang = "en";
    }
  },
  mounted() {
    //
  },
  methods: {
    async browse() {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/abc/chapter/browse";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        this.book = resp.Book;
        this.chapters = resp.Chapter;
      };
      this.api.params = {
        bookKey: this.$route.params.bookKey,
      };
      await this.$api.fetch(this.api);
    },
    async getQuest() {
      this.quests = [];
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/quest/visit";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        this.quests = resp.Quest;
      };
      this.api.params = {
        model: "Book",
        modelKey: this.$route.params.bookKey,
      };

      await this.$api.fetch(this.api);
    },
    unlock(chapter) {
      this.selectedItem = chapter;
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/abc/chapter/unlock";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = () => {
        this.api.isLoading = false;
        this.browse();
        this.chapterUnlocked = true;
      };
      this.api.params = {
        chapterKey: chapter.key,
        bookKey: this.book.key,
      };
      this.$api.fetch(this.api);
    },
    claimQuest(item) {
      this.selectedItem = item;
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/quest/open";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        this.rewards = resp.Reward;
        this.rwdDialog = true;
        this.getQuest();
      };
      this.api.params = {
        key: item.key,
      };
      this.$api.fetch(this.api);
    },
    read(chapter) {
      this.$router.push({
        name: "PageABCBookReader",
        params: { bookKey: this.book.key, chapterKey: chapter.key },
      });
    },
    exit() {
      this.$router.push({
        name: "PageExploreLanding",
      });
    },
    resetBook() {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/abc/book/reset";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = () => {
        this.api.isLoading = false;
        this.exit();
      };
      this.api.params = {
        bookKey: this.$route.params.bookKey,
      };
      this.$api.fetch(this.api);
    },
  },
};
</script>

<style scoped>
.pulse:hover {
  animation: none;
}

.pulse {
  animation: beat 0.5s infinite alternate;
  transform-origin: center;
}

/* Heart beat animation */
@keyframes beat {
  to {
    transform: scale(1.1);
  }
}

.constant-tilt-shake {
  animation: tilt-shaking 2s infinite;
}

.constant-tilt-shake:hover {
  animation: none;
}

@keyframes tilt-shaking {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  3% {
    transform: translate(2px, -2px) rotate(5deg);
  }
  6% {
    transform: translate(0, 0) rotate(0eg);
  }
  9% {
    transform: translate(-2px, -2px) rotate(-5deg);
  }
  12% {
    transform: translate(0, 0) rotate(0deg);
  }
  15% {
    transform: translate(2px, -2px) rotate(5deg);
  }
  18% {
    transform: translate(0, 0) rotate(0eg);
  }
  21% {
    transform: translate(-2px, -2px) rotate(-5deg);
  }
  24% {
    transform: translate(0, 0) rotate(0deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

.menu-button {
  padding: 0 5px;
  cursor: pointer;
  width: 33.33%;
  display: flex;
  justify-content: center;
}

.menu-item {
  cursor: pointer;
}

.menu-item:hover {
  scale: 1.1;
}

.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}

.text-stroke-sm {
  color: #fff;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}

.list-card {
  padding: 5px;
  color: #785c3a;
  border-radius: 5px;
  border: #ccaf8b 4px solid;
  background-color: #fff9f1;
  overflow-x: hidden !important;
}

.main-body {
  /*background-color: #c7e5e9;*/
  background-color: rgba(214, 132, 49, 0.3);
}

.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 80px 0 40px 0;
  /* background-color: #f5dfc5; */
}
</style>