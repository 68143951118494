var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screen-wrap"},[_c('v-app',{staticClass:"hmc-app"},[_c('v-main',{staticClass:"main-body"},[_c('v-container',{staticClass:"app-body"},[(_vm.api.isError)?_c('ApiErrorDialog',{attrs:{"api":_vm.api}}):_vm._e(),(_vm.book)?_c('div',{staticClass:"pe-2 ps-3 app-title text-h5"},[_vm._v(" "+_vm._s(_vm.book.name)+" "),_c('v-spacer'),_c('CloseBtn',{attrs:{"size":"40","callback":_vm.exit}})],1):_vm._e(),_c('div',{staticClass:"px-5"},[(_vm.quests.length > 0)?_c('div',[_c('div',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t("string.quest")))]),_c('v-carousel',{staticClass:"pb-8",attrs:{"show-arrows":false,"height":"auto","hide-delimiter-background":"","draggable":"","value":99}},_vm._l((_vm.quests),function(item,i){return _c('v-carousel-item',{key:i + 'q',attrs:{"value":_vm.quests[i - 1] && _vm.quests[i - 1].PlayerQuest.timestampClaimed
                    ? 99
                    : i}},[_c('v-card',{staticClass:"my-2 list-card",attrs:{"flat":""}},[_c('div',{staticClass:"d-flex flex-no-wrap align-center"},[_vm._l((item.Reward),function(rwd,j){return _c('div',{key:j + 'qi',staticClass:"text-center mx-4 mt-2"},[_c('v-img',{attrs:{"contain":"","max-height":"50","max-width":"50","src":rwd.Item.images.icon.url}}),_c('div',{staticClass:"me-1"},[_vm._v("+"+_vm._s(rwd.quantity))])],1)}),_c('div',{staticClass:"pa-2",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"d-flex flex-no-wrap align-center"},[_c('div',{staticClass:"pe-3",staticStyle:{"width":"100%"}},[_c('v-progress-linear',{attrs:{"value":item.progress,"color":"green","height":"20","rounded":""}},[_vm._v(" "+_vm._s(item.numerator)+" / "+_vm._s(item.denominator))])],1),(item.PlayerQuest.timestampClaimed)?_c('v-icon',{attrs:{"large":"","color":"green"}},[_vm._v(" mdi-check-circle ")]):(item.progress == 100)?_c('v-btn',{staticClass:"text-h6 constant-tilt-shake",attrs:{"color":"yellow","loading":_vm.api.isLoading && _vm.selectedItem == item},on:{"click":function($event){return _vm.claimQuest(item)}}},[_vm._v(" "+_vm._s(_vm.$t("action.claim"))+" ")]):_c('v-btn',{attrs:{"color":"grey","dark":"","depressed":""}},[_c('v-icon',[_vm._v(" mdi-lock ")])],1)],1)])],2)])],1)}),1)],1):_vm._e(),(_vm.quests.length > 0)?_c('v-divider',{staticClass:"my-3"}):_vm._e(),(_vm.chapters.length > 0)?_c('div',[_c('div',{staticClass:"text-h5 my-4"},[_vm._v(_vm._s(_vm.$t("string.chapters")))]),_vm._l((_vm.chapters),function(item,i){return _c('v-card',{key:i + 'all',staticClass:"my-2 list-card",attrs:{"flat":""}},[_c('div',{staticClass:"d-flex flex-no-wrap"},[_c('div',{staticClass:"d-flex flex-column justify-space-between pa-2",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex align-center"},[(
                        item.PlayerChapter.length > 0 &&
                        item.PlayerChapter[0].isRead
                      )?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-circle ")]):_c('v-icon',[_vm._v("mdi-circle-outline")]),_c('div',{staticClass:"mx-1"},[_vm._v(" "+_vm._s(JSON.parse(item.translatableSection)[_vm.$_getLocale()] ? JSON.parse(item.translatableSection)[ _vm.$_getLocale() ] : JSON.parse(item.translatableSection)[_vm.subjectLang])+" ")]),_c('v-spacer'),(item.PlayerChapter.length > 0)?_c('div',_vm._l((item.totalCollectableStar),function(star,i){return _c('v-icon',{key:i,attrs:{"color":"amber"}},[_vm._v(_vm._s(item.PlayerChapter[0].topStar > i ? "mdi-star" : "mdi-star-outline"))])}),1):(item.minStarToUnlock)?_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',[_vm._v("mdi-star")]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(item.minStarToUnlock))])],1):_vm._e()],1),_c('div',{staticClass:"d-flex align-center mt-3"},[_c('div',{staticClass:"text-h5 mx-2"},[_vm._v(" "+_vm._s(item.PlayerChapter.length > 0 ? JSON.parse(item.translatableName)[_vm.$_getLocale()] ? JSON.parse(item.translatableName)[_vm.$_getLocale()] : JSON.parse(item.translatableName)[_vm.subjectLang] : "???")+" ")]),_c('v-divider'),(item.PlayerChapter.length > 0)?_c('v-btn',{staticClass:"text-h6",attrs:{"color":"blue","dark":""},on:{"click":function($event){return _vm.read(item)}}},[_vm._v(" "+_vm._s(_vm.$t("string.read"))+" ")]):(
                        (_vm.book.PlayerBook[0].totalStar >=
                          item.minStarToUnlock &&
                          i == 0) ||
                        (_vm.book.PlayerBook[0].totalStar >=
                          item.minStarToUnlock &&
                          i > 0 &&
                          _vm.chapters[i - 1].PlayerChapter.length > 0 &&
                          _vm.chapters[i - 1].PlayerChapter[0].isRead)
                      )?_c('v-btn',{staticClass:"constant-tilt-shake",attrs:{"color":"green","dark":"","loading":_vm.api.isLoading && _vm.selectedItem == item},on:{"click":function($event){return _vm.unlock(item)}}},[_c('v-icon',[_vm._v(" mdi-lock ")])],1):_c('v-btn',{attrs:{"depressed":"","color":"grey","dark":""}},[_c('v-icon',[_vm._v(" mdi-lock ")])],1)],1)])])])})],2):_vm._e(),_c('div',{staticClass:"d-flex justify-right mt-6"},[_c('v-divider',{staticClass:"my-3"}),(_vm.settings.debug)?_c('v-btn',{attrs:{"small":"","dark":"","color":"black"},on:{"click":_vm.resetBook}},[_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v(" mdi-record ")]),_vm._v(" Reset ")],1):_vm._e()],1)],1),(_vm.chapterUnlocked)?_c('UnlockDialog',{attrs:{"callbackClose":() => (_vm.chapterUnlocked = false),"showDialog":true,"title":_vm.$t('string.chapterUnlocked'),"message":_vm.selectedItem.name}}):_vm._e(),(_vm.rwdDialog)?_c('RewardDialog',{attrs:{"callbackClose":() => (_vm.rwdDialog = false),"showDialog":true,"rewards":_vm.rewards}}):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }